var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"grid-list-md":""}},[_c('v-layout',{attrs:{"wrap":""}},[(_vm.showemptyCard)?_c('v-flex',{attrs:{"xs12":"","md12":""}},[_c('v-hover',{attrs:{"open-delay":"100","close-delay":"100"},scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-card',{staticClass:"mx-auto text-center",attrs:{"elevation":hover ? 16 : 2,"max-width":"300"}},[_c('v-list-item',{attrs:{"three-line":""}},[_c('v-list-item-content',[_c('div',{staticStyle:{"color":"gray","font-size":"20px"}},[_vm._v("لا توجد مهام")]),_c('v-list-item-title',{staticClass:"headline mb-1",staticStyle:{"color":"gray","font-size":"15px"}})],1)],1)],1)]}}],null,false,1713215121)})],1):_vm._e(),(_vm.showNewTasks)?_c('v-flex',{staticClass:"mb-5"},[_c('v-card',{staticClass:"mx-auto",attrs:{"elevation":"4","width":"1000"}},[_c('v-card-title',{attrs:{"primary-title":""}},[_c('h4',{staticClass:"headline mb-0",staticStyle:{"color":"gray"}},[_c('b',[_vm._v("المهام المعينة")])]),_c('v-chip',{staticClass:"ma-2",attrs:{"color":"green","text-color":"white"}},[_vm._v(_vm._s(_vm.NewTasksCount))]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","dark":""},on:{"click":function($event){return _vm.$router.push({
                  name: 'all-tasks',
                  params: { type: 'new-tasks' }
                })}}},[_c('b',[_vm._v("عرض الكل")])])],1),_c('v-card-text',[_c('v-divider'),_c('v-simple-table',{attrs:{"fixed-header":"","width":"900"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-center"},[_vm._v("اسم المهمة")]),_c('th',{staticClass:"text-center"},[_vm._v("نوع المهمة")]),_c('th',{staticClass:"text-center"},[_vm._v("تاريخ البداية")]),_c('th',{staticClass:"text-center"},[_vm._v("تاريخ النهاية")]),_c('th',{staticClass:"text-center"},[_vm._v("خيارات")])])]),(_vm.NewTasks.length > 0)?_c('tbody',_vm._l((_vm.NewTasks),function(item){return _c('tr',{key:item.idtask},[_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(item.name))]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.typeId == 1 ? "زيارة" : item.typeId == 2 ? "تنبيه" : item.typeId == 3 ? "طلب معلومات " : "-")+" ")]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.createdDate(item.startDate))+" ")]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.createdDate(item.endDate)))]),_c('td',{staticClass:"text-center"},[_c('div',[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"green","text-color":"white","small":""},on:{"click":function($event){return _vm.$router.push({
                              name: 'show-task',
                              params: { task: item, taskId: item.idtask }
                            })}}},[_vm._v(" mdi-eye ")]),(_vm.role == 'Admin')?_c('v-icon',{staticClass:"mr-2",attrs:{"color":"blue","text-color":"white","small":""},on:{"click":function($event){return _vm.$router.push({
                              name: 'edit-task',
                              params: { task: item, taskId: item.idtask }
                            })}}},[_vm._v(" mdi-pencil ")]):_vm._e(),(_vm.role == 'Admin')?_c('v-icon',{attrs:{"color":"red","text-color":"white","small":""},on:{"click":function($event){return _vm.deleteItem(item.idtask, 1)}}},[_vm._v(" mdi-delete ")]):_vm._e()],1)])])}),0):_c('tbody',[_c('tr',[_c('td',{staticClass:"text-center",staticStyle:{"color":"gray","font-size":"12px"},attrs:{"colspan":"5"}},[_vm._v(" لا توجد بيانات لعرضها ")])])])]},proxy:true}],null,false,2041784238)})],1)],1)],1):_vm._e(),(_vm.showDoingTasks)?_c('v-flex',{staticClass:"mb-5"},[_c('v-card',{staticClass:"mx-auto",attrs:{"elevation":"4","width":"1000"}},[_c('v-card-title',{attrs:{"primary-title":""}},[_c('h4',{staticClass:"headline mb-0",staticStyle:{"color":"gray"}},[_c('b',[_vm._v("المهام قيد التقدم")])]),_c('v-chip',{staticClass:"ma-2",attrs:{"color":"green","text-color":"white"}},[_vm._v(_vm._s(_vm.doingTaskTasksCount))]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","dark":""},on:{"click":function($event){return _vm.$router.push({
                  name: 'all-tasks',
                  params: { type: 'doing-task-tasks' }
                })}}},[_c('b',[_vm._v("عرض الكل")])])],1),_c('v-divider'),_c('v-card-text',[_c('v-simple-table',{attrs:{"fixed-header":"","width":"900"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-center"},[_vm._v("اسم المهمة")]),_c('th',{staticClass:"text-center"},[_vm._v("نوع المهمة")]),_c('th',{staticClass:"text-center"},[_vm._v("تاريخ البداية")]),_c('th',{staticClass:"text-center"},[_vm._v("تاريخ النهاية")]),_c('th',{staticClass:"text-center"},[_vm._v("خيارات")])])]),(_vm.doingTaskTasks.length > 0)?_c('tbody',_vm._l((_vm.doingTaskTasks),function(item){return _c('tr',{key:item.idtask},[_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(item.name))]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.typeId == 1 ? "زيارة" : item.typeId == 2 ? "تنبيه" : item.typeId == 3 ? "طلب معلومات " : "-")+" ")]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.createdDate(item.startDate))+" ")]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.createdDate(item.endDate)))]),_c('td',{staticClass:"text-center"},[_c('div',[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"green","text-color":"white","small":""},on:{"click":function($event){return _vm.$router.push({
                              name: 'show-task',
                              params: { task: item, taskId: item.idtask }
                            })}}},[_vm._v(" mdi-eye ")]),(_vm.role == 'Admin')?_c('v-icon',{staticClass:"mr-2",attrs:{"color":"blue","text-color":"white","small":""},on:{"click":function($event){return _vm.$router.push({
                              name: 'edit-task',
                              params: { task: item, taskId: item.idtask }
                            })}}},[_vm._v(" mdi-pencil ")]):_vm._e(),(_vm.role == 'Admin')?_c('v-icon',{attrs:{"color":"red","text-color":"white","small":""},on:{"click":function($event){return _vm.deleteItem(item.idtask, 3)}}},[_vm._v(" mdi-delete ")]):_vm._e()],1)])])}),0):_c('tbody',[_c('tr',[_c('td',{staticClass:"text-center",staticStyle:{"color":"gray","font-size":"12px"},attrs:{"colspan":"5"}},[_vm._v(" لا توجد بيانات لعرضها ")])])])]},proxy:true}],null,false,1735835436)})],1)],1)],1):_vm._e(),(_vm.showWaitingReviewTasks)?_c('v-flex',{staticClass:"mb-5"},[_c('v-card',{staticClass:"mx-auto",attrs:{"elevation":"4","width":"1000"}},[_c('v-card-title',{attrs:{"primary-title":""}},[_c('h4',{staticClass:"headline mb-0",staticStyle:{"color":"gray"}},[_c('b',[_vm._v("المهام بانتظار المراجعة")])]),_c('v-chip',{staticClass:"ma-2",attrs:{"color":"green","text-color":"white"}},[_vm._v(_vm._s(_vm.WaitingReviewTasksCount))]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","dark":""},on:{"click":function($event){return _vm.$router.push({
                  name: 'all-tasks',
                  params: { type: 'waiting-review-tasks' }
                })}}},[_c('b',[_vm._v("عرض الكل")])])],1),_c('v-divider'),_c('v-card-text',[_c('v-simple-table',{attrs:{"fixed-header":"","width":"900"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-center"},[_vm._v("اسم المهمة")]),_c('th',{staticClass:"text-center"},[_vm._v("نوع المهمة")]),_c('th',{staticClass:"text-center"},[_vm._v("تاريخ البداية")]),_c('th',{staticClass:"text-center"},[_vm._v("تاريخ النهاية")]),_c('th',{staticClass:"text-center"},[_vm._v("خيارات")])])]),(_vm.WaitingReviewTasks.length > 0)?_c('tbody',_vm._l((_vm.WaitingReviewTasks),function(item){return _c('tr',{key:item.idtask},[_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(item.name))]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.typeId == 1 ? "زيارة" : item.typeId == 2 ? "تنبيه" : item.typeId == 3 ? "طلب معلومات " : "-")+" ")]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.createdDate(item.startDate))+" ")]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.createdDate(item.endDate)))]),_c('td',{staticClass:"text-center"},[_c('div',[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"green","text-color":"white","small":""},on:{"click":function($event){return _vm.$router.push({
                              name: 'show-task',
                              params: { task: item, taskId: item.idtask }
                            })}}},[_vm._v(" mdi-eye ")]),(_vm.role == 'Admin')?_c('v-icon',{staticClass:"mr-2",attrs:{"color":"blue","text-color":"white","small":""},on:{"click":function($event){return _vm.$router.push({
                              name: 'edit-task',
                              params: { task: item, taskId: item.idtask }
                            })}}},[_vm._v(" mdi-pencil ")]):_vm._e(),(_vm.role == 'Admin')?_c('v-icon',{attrs:{"color":"red","text-color":"white","small":""},on:{"click":function($event){return _vm.deleteItem(item.idtask, 4)}}},[_vm._v(" mdi-delete ")]):_vm._e()],1)])])}),0):_c('tbody',[_c('tr',[_c('td',{staticClass:"text-center",staticStyle:{"color":"gray","font-size":"12px"},attrs:{"colspan":"5"}},[_vm._v(" لا توجد بيانات لعرضها ")])])])]},proxy:true}],null,false,2007339851)})],1)],1)],1):_vm._e(),(_vm.showArchiveTasks)?_c('v-flex',{staticClass:"mb-5"},[_c('v-card',{staticClass:"mx-auto",attrs:{"elevation":"4","width":"1000"}},[_c('v-card-title',{attrs:{"primary-title":""}},[_c('h4',{staticClass:"headline mb-0",staticStyle:{"color":"gray"}},[_c('b',[_vm._v("المهام المنجزة")])]),_c('v-chip',{staticClass:"ma-2",attrs:{"color":"green","text-color":"white"}},[_vm._v(_vm._s(_vm.ArchiveTasksCount))]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","dark":""},on:{"click":function($event){return _vm.$router.push({
                  name: 'all-tasks',
                  params: { type: 'archive-tasks' }
                })}}},[_c('b',[_vm._v("عرض الكل")])])],1),_c('v-divider'),_c('v-card-text',[_c('v-simple-table',{attrs:{"fixed-header":"","width":"900"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-center"},[_vm._v("اسم المهمة")]),_c('th',{staticClass:"text-center"},[_vm._v("نوع المهمة")]),_c('th',{staticClass:"text-center"},[_vm._v("تاريخ البداية")]),_c('th',{staticClass:"text-center"},[_vm._v("تاريخ النهاية")]),_c('th',{staticClass:"text-center"},[_vm._v("خيارات")])])]),(_vm.ArchiveTasks.length > 0)?_c('tbody',_vm._l((_vm.ArchiveTasks),function(item){return _c('tr',{key:item.idtask},[_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(item.name))]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.typeId == 1 ? "زيارة" : item.typeId == 2 ? "تنبيه" : item.typeId == 3 ? "طلب معلومات " : "-")+" ")]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.createdDate(item.startDate))+" ")]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.createdDate(item.endDate)))]),_c('td',{staticClass:"text-center"},[_c('div',[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"green","text-color":"white","small":""},on:{"click":function($event){return _vm.$router.push({
                              name: 'show-task',
                              params: { task: item, taskId: item.idtask }
                            })}}},[_vm._v(" mdi-eye ")]),(_vm.role == 'Admin')?_c('v-icon',{staticClass:"mr-2",attrs:{"color":"blue","text-color":"white","small":""},on:{"click":function($event){return _vm.$router.push({
                              name: 'edit-task',
                              params: { task: item, taskId: item.idtask }
                            })}}},[_vm._v(" mdi-pencil ")]):_vm._e(),(_vm.role == 'Admin')?_c('v-icon',{attrs:{"color":"red","text-color":"white","small":""},on:{"click":function($event){return _vm.deleteItem(item.idtask, 2)}}},[_vm._v(" mdi-delete ")]):_vm._e()],1)])])}),0):_c('tbody',[_c('tr',[_c('td',{staticClass:"text-center",staticStyle:{"color":"gray","font-size":"12px"},attrs:{"colspan":"5"}},[_vm._v(" لا توجد بيانات لعرضها ")])])])]},proxy:true}],null,false,2294669965)})],1)],1)],1):_vm._e()],1),_c('v-dialog',{attrs:{"max-width":"300px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("هل أنت متأكد من الحذف؟")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text-color":"white","text":""},on:{"click":_vm.closeDelete}},[_vm._v("إلغاء")]),_c('v-btn',{attrs:{"color":"gray","text-color":"white","text":""},on:{"click":_vm.deleteItemConfirm}},[_vm._v("موافق")]),_c('v-spacer')],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }