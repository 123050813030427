<template>
  <v-container grid-list-md>
    <v-layout wrap>
      <v-flex xs12 md12 v-if="showemptyCard">
        <v-hover v-slot:default="{ hover }" open-delay="100" close-delay="100">
          <v-card
            :elevation="hover ? 16 : 2"
            class="mx-auto text-center"
            max-width="300"
          >
            <v-list-item three-line>
              <v-list-item-content>
                <div style="color: gray; font-size: 20px">لا توجد مهام</div>
                <v-list-item-title
                  style="color: gray; font-size: 15px"
                  class="headline mb-1"
                ></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-hover>
      </v-flex>

      <v-flex v-if="showNewTasks" class="mb-5">
        <v-card elevation="4" width="1000" class="mx-auto">
          <v-card-title primary-title>
            <h4 class="headline mb-0" style="color: gray">
              <b>المهام المعينة</b>
            </h4>
            <v-chip class="ma-2" color="green" text-color="white">{{
              NewTasksCount
            }}</v-chip>
            <v-spacer></v-spacer>
            <v-btn
              @click="
                $router.push({
                  name: 'all-tasks',
                  params: { type: 'new-tasks' }
                })
              "
              color="primary"
              dark
            >
              <b>عرض الكل</b>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-divider></v-divider>
            <v-simple-table fixed-header width="900">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-center">اسم المهمة</th>
                    <th class="text-center">نوع المهمة</th>
                    <th class="text-center">تاريخ البداية</th>
                    <th class="text-center">تاريخ النهاية</th>
                    <th class="text-center">خيارات</th>
                  </tr>
                </thead>
                <tbody v-if="NewTasks.length > 0">
                  <tr v-for="item in NewTasks" :key="item.idtask">
                    <td class="text-center">{{ item.name }}</td>
                    <td class="text-center">
                      {{
                        item.typeId == 1
                          ? "زيارة"
                          : item.typeId == 2
                          ? "تنبيه"
                          : item.typeId == 3
                          ? "طلب معلومات "
                          : "-"
                      }}
                    </td>
                    <td class="text-center">
                      {{ createdDate(item.startDate) }}
                    </td>
                    <td class="text-center">{{ createdDate(item.endDate) }}</td>
                    <td class="text-center">
                      <div>
                        <v-icon
                          color="green"
                          text-color="white"
                          small
                          class="mr-2"
                          @click="
                            $router.push({
                              name: 'show-task',
                              params: { task: item, taskId: item.idtask }
                            })
                          "
                        >
                          mdi-eye
                        </v-icon>
                        <v-icon
                          color="blue"
                          v-if="role == 'Admin'"
                          text-color="white"
                          small
                          class="mr-2"
                          @click="
                            $router.push({
                              name: 'edit-task',
                              params: { task: item, taskId: item.idtask }
                            })
                          "
                        >
                          mdi-pencil
                        </v-icon>
                        <v-icon
                          color="red"
                          v-if="role == 'Admin'"
                          text-color="white"
                          small
                          @click="deleteItem(item.idtask, 1)"
                        >
                          mdi-delete
                        </v-icon>
                      </div>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td
                      colspan="5"
                      class="text-center"
                      style="color: gray; font-size: 12px"
                    >
                      لا توجد بيانات لعرضها
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-flex>

      <v-flex v-if="showDoingTasks" class="mb-5">
        <v-card elevation="4" width="1000" class="mx-auto">
          <v-card-title primary-title>
            <h4 class="headline mb-0" style="color: gray">
              <b>المهام قيد التقدم</b>
            </h4>
            <v-chip class="ma-2" color="green" text-color="white">{{
              doingTaskTasksCount
            }}</v-chip>
            <v-spacer></v-spacer>
            <v-btn
              @click="
                $router.push({
                  name: 'all-tasks',
                  params: { type: 'doing-task-tasks' }
                })
              "
              color="primary"
              dark
            >
              <b>عرض الكل</b>
            </v-btn>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-simple-table fixed-header width="900">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-center">اسم المهمة</th>
                    <th class="text-center">نوع المهمة</th>
                    <th class="text-center">تاريخ البداية</th>
                    <th class="text-center">تاريخ النهاية</th>
                    <th class="text-center">خيارات</th>
                  </tr>
                </thead>
                <tbody v-if="doingTaskTasks.length > 0">
                  <tr v-for="item in doingTaskTasks" :key="item.idtask">
                    <td class="text-center">{{ item.name }}</td>
                    <td class="text-center">
                      {{
                        item.typeId == 1
                          ? "زيارة"
                          : item.typeId == 2
                          ? "تنبيه"
                          : item.typeId == 3
                          ? "طلب معلومات "
                          : "-"
                      }}
                    </td>
                    <td class="text-center">
                      {{ createdDate(item.startDate) }}
                    </td>
                    <td class="text-center">{{ createdDate(item.endDate) }}</td>
                    <td class="text-center">
                      <div>
                        <v-icon
                          color="green"
                          text-color="white"
                          small
                          class="mr-2"
                          @click="
                            $router.push({
                              name: 'show-task',
                              params: { task: item, taskId: item.idtask }
                            })
                          "
                        >
                          mdi-eye
                        </v-icon>
                        <v-icon
                          color="blue"
                          v-if="role == 'Admin'"
                          text-color="white"
                          small
                          class="mr-2"
                          @click="
                            $router.push({
                              name: 'edit-task',
                              params: { task: item, taskId: item.idtask }
                            })
                          "
                        >
                          mdi-pencil
                        </v-icon>
                        <v-icon
                          color="red"
                          v-if="role == 'Admin'"
                          text-color="white"
                          small
                          @click="deleteItem(item.idtask, 3)"
                        >
                          mdi-delete
                        </v-icon>
                      </div>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td
                      colspan="5"
                      class="text-center"
                      style="color: gray; font-size: 12px"
                    >
                      لا توجد بيانات لعرضها
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-flex>

      <v-flex class="mb-5" v-if="showWaitingReviewTasks">
        <v-card elevation="4" width="1000" class="mx-auto">
          <v-card-title primary-title>
            <h4 class="headline mb-0" style="color: gray">
              <b>المهام بانتظار المراجعة</b>
            </h4>
            <v-chip class="ma-2" color="green" text-color="white">{{
              WaitingReviewTasksCount
            }}</v-chip>
            <v-spacer></v-spacer>
            <v-btn
              @click="
                $router.push({
                  name: 'all-tasks',
                  params: { type: 'waiting-review-tasks' }
                })
              "
              color="primary"
              dark
            >
              <b>عرض الكل</b>
            </v-btn>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-simple-table fixed-header width="900">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-center">اسم المهمة</th>
                    <th class="text-center">نوع المهمة</th>
                    <th class="text-center">تاريخ البداية</th>
                    <th class="text-center">تاريخ النهاية</th>
                    <th class="text-center">خيارات</th>
                  </tr>
                </thead>
                <tbody v-if="WaitingReviewTasks.length > 0">
                  <tr v-for="item in WaitingReviewTasks" :key="item.idtask">
                    <td class="text-center">{{ item.name }}</td>
                    <td class="text-center">
                      {{
                        item.typeId == 1
                          ? "زيارة"
                          : item.typeId == 2
                          ? "تنبيه"
                          : item.typeId == 3
                          ? "طلب معلومات "
                          : "-"
                      }}
                    </td>
                    <td class="text-center">
                      {{ createdDate(item.startDate) }}
                    </td>
                    <td class="text-center">{{ createdDate(item.endDate) }}</td>
                    <td class="text-center">
                      <div>
                        <v-icon
                          color="green"
                          text-color="white"
                          small
                          class="mr-2"
                          @click="
                            $router.push({
                              name: 'show-task',
                              params: { task: item, taskId: item.idtask }
                            })
                          "
                        >
                          mdi-eye
                        </v-icon>
                        <v-icon
                          color="blue"
                          v-if="role == 'Admin'"
                          text-color="white"
                          small
                          class="mr-2"
                          @click="
                            $router.push({
                              name: 'edit-task',
                              params: { task: item, taskId: item.idtask }
                            })
                          "
                        >
                          mdi-pencil
                        </v-icon>
                        <v-icon
                          color="red"
                          v-if="role == 'Admin'"
                          text-color="white"
                          small
                          @click="deleteItem(item.idtask, 4)"
                        >
                          mdi-delete
                        </v-icon>
                      </div>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td
                      colspan="5"
                      class="text-center"
                      style="color: gray; font-size: 12px"
                    >
                      لا توجد بيانات لعرضها
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-flex>

      <v-flex class="mb-5" v-if="showArchiveTasks">
        <v-card elevation="4" width="1000" class="mx-auto">
          <v-card-title primary-title>
            <h4 class="headline mb-0" style="color: gray">
              <b>المهام المنجزة</b>
            </h4>
            <v-chip class="ma-2" color="green" text-color="white">{{
              ArchiveTasksCount
            }}</v-chip>
            <v-spacer></v-spacer>
            <v-btn
              @click="
                $router.push({
                  name: 'all-tasks',
                  params: { type: 'archive-tasks' }
                })
              "
              color="primary"
              dark
            >
              <b>عرض الكل</b>
            </v-btn>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-simple-table fixed-header width="900">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-center">اسم المهمة</th>
                    <th class="text-center">نوع المهمة</th>
                    <th class="text-center">تاريخ البداية</th>
                    <th class="text-center">تاريخ النهاية</th>
                    <th class="text-center">خيارات</th>
                  </tr>
                </thead>
                <tbody v-if="ArchiveTasks.length > 0">
                  <tr v-for="item in ArchiveTasks" :key="item.idtask">
                    <td class="text-center">{{ item.name }}</td>
                    <td class="text-center">
                      {{
                        item.typeId == 1
                          ? "زيارة"
                          : item.typeId == 2
                          ? "تنبيه"
                          : item.typeId == 3
                          ? "طلب معلومات "
                          : "-"
                      }}
                    </td>
                    <td class="text-center">
                      {{ createdDate(item.startDate) }}
                    </td>
                    <td class="text-center">{{ createdDate(item.endDate) }}</td>
                    <td class="text-center">
                      <div>
                        <v-icon
                          color="green"
                          text-color="white"
                          small
                          class="mr-2"
                          @click="
                            $router.push({
                              name: 'show-task',
                              params: { task: item, taskId: item.idtask }
                            })
                          "
                        >
                          mdi-eye
                        </v-icon>
                        <v-icon
                          color="blue"
                          v-if="role == 'Admin'"
                          text-color="white"
                          small
                          class="mr-2"
                          @click="
                            $router.push({
                              name: 'edit-task',
                              params: { task: item, taskId: item.idtask }
                            })
                          "
                        >
                          mdi-pencil
                        </v-icon>
                        <v-icon
                          color="red"
                          v-if="role == 'Admin'"
                          text-color="white"
                          small
                          @click="deleteItem(item.idtask, 2)"
                        >
                          mdi-delete
                        </v-icon>
                      </div>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td
                      colspan="5"
                      class="text-center"
                      style="color: gray; font-size: 12px"
                    >
                      لا توجد بيانات لعرضها
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>

    <v-dialog v-model="dialogDelete" max-width="300px">
      <v-card>
        <v-card-title class="headline">هل أنت متأكد من الحذف؟</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text-color="white" text @click="closeDelete"
            >إلغاء</v-btn
          >
          <v-btn color="gray" text-color="white" text @click="deleteItemConfirm"
            >موافق</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      showNewTasks: true,
      showDoingTasks: true,
      showWaitingReviewTasks: true,
      showArchiveTasks: true,

      NewTasks: [],
      NewTasksCount: 0,

      doingTaskTasks: [],
      doingTaskTasksCount: 0,

      WaitingReviewTasks: [],
      WaitingReviewTasksCount: 0,

      ArchiveTasks: [],
      ArchiveTasksCount: 0,

      dialogDelete: false,
      deletedItem: 0,
      cardId: 0
    };
  },
  computed: {
    showemptyCard() {
      if (
        !this.showNewTasks &&
        !this.showDoingTasks &&
        !this.showWaitingReviewTasks &&
        !this.showArchiveTasks
      )
        return true;
      else return false;
    }
  },
  methods: {
    deleteItem(item, id) {
      this.deletedItem = item;
      this.dialogDelete = true;
      this.cardId = id;
    },
    async deleteItemConfirm() {
      this.dialogDelete = false;
      await this.ApiService.delete("task/DeleteTask/" + this.deletedItem)
        .then(() => {
          this.hel.showSnackMsg("تمت العملية بنجاح", "#58dd5b", 2000);
          this.cardId == 1
            ? this.getNewTasksCountTasks()
            : this.cardId == 2
            ? this.getArchiveTasks()
            : this.cardId == 3
            ? this.getDoingTasksByUserTasks()
            : this.getWaitingReviewTasks();
        })
        .catch(() => {
          this.hel.showSnackMsg("فشلت العملية", "#ff0000", 2000);
        });
    },
    closeDelete() {
      this.deletedItem = 0;
      this.dialogDelete = false;
      this.cardId = 0;
    },
    getNewTasksCountTasks() {
      this.ApiService.get("task/NewTasksByUser?user=" + this.user.id)
        .then(res => {
          this.NewTasks = [];
          this.NewTasksCount = res.data.responseData.length;
          if (this.NewTasksCount == 0) this.showNewTasks = false;
          if (this.NewTasksCount > 3) {
            let k = 0;
            res.data.responseData.forEach(el => {
              if (k > 2) return;
              if (el != null) {
                this.NewTasks.push(el);
                k++;
              }
            });
          } else {
            res.data.responseData.forEach(el => {
              if (el != null) {
                this.NewTasks.push(el);
              }
            });
          }
        })
        .catch(() => {});
    },
    getDoingTasksByUserTasks() {
      this.ApiService.get("task/DoingTasksByUser?user=" + this.user.id)
        .then(res => {
          this.doingTaskTasks = [];
          this.doingTaskTasksCount = res.data.responseData.length;
          if (this.doingTaskTasksCount == 0) this.showDoingTasks = false;
          if (this.doingTaskTasksCount > 3) {
            let k = 0;
            res.data.responseData.forEach(el => {
              if (k > 2) return;
              if (el != null) {
                this.doingTaskTasks.push(el);
                k++;
              }
            });
          } else {
            res.data.responseData.forEach(el => {
              if (el != null) {
                this.doingTaskTasks.push(el);
              }
            });
          }
        })
        .catch(() => {});
    },

    getWaitingReviewTasks() {
      this.ApiService.get("Task/WaitingTasksByUser?user=" + this.user.id)
        .then(res => {
          this.WaitingReviewTasks = [];
          this.WaitingReviewTasksCount = res.data.responseData.length;
          if (this.WaitingReviewTasksCount == 0)
            this.showWaitingReviewTasks = false;
          if (this.WaitingReviewTasksCount > 3) {
            let k = 0;
            res.data.responseData.forEach(el => {
              if (k > 2) return;
              if (el != null) {
                this.WaitingReviewTasks.push(el);
                k++;
              }
            });
          } else {
            res.data.responseData.forEach(el => {
              if (el != null) {
                this.WaitingReviewTasks.push(el);
              }
            });
          }
        })
        .catch(() => {});
    },

    getArchiveTasks() {
      this.ApiService.get("task/ArchiveTasksByUser?user=" + this.user.id)
        .then(res => {
          this.ArchiveTasks = [];
          this.ArchiveTasksCount = res.data.responseData.length;
          if (this.ArchiveTasksCount == 0) this.showArchiveTasks = false;
          if (this.ArchiveTasksCount > 3) {
            let k = 0;
            res.data.responseData.forEach(el => {
              if (k > 2) return;
              if (el != null) {
                this.ArchiveTasks.push(el);
                k++;
              }
            });
          } else {
            res.data.responseData.forEach(el => {
              if (el != null) {
                this.ArchiveTasks.push(el);
              }
            });
          }
        })
        .catch(() => {});
    },

    createdDate(date) {
      const current_datetime = new Date(date);
      return current_datetime.toLocaleDateString("en-US");
    }
  },
  created() {
    this.user = JSON.parse(window.localStorage.getItem("authUser"));
    this.role = window.localStorage.getItem("roles");
    this.getNewTasksCountTasks();
    this.getDoingTasksByUserTasks();
    this.getWaitingReviewTasks();
    this.getArchiveTasks();
  }
};
</script>
<style lang="scss"></style>
